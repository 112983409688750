var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var CompaniesState_1;
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { Action, createSelector, Selector, State } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { GetCompany, GetCompanySuccess, SetAuthenticatedCompany } from '@store/companies/companies.actions';
import { CompanyWebservice } from '@wizbii/webservices';
import { throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
export class CompaniesStateModel {
}
const defaultState = {
    authenticatedCompanyId: null,
    companies: {},
    error: null,
};
let CompaniesState = CompaniesState_1 = class CompaniesState {
    constructor(companyWebservice) {
        this.companyWebservice = companyWebservice;
    }
    static company(companyId) {
        return createSelector([CompaniesState_1], (state) => {
            return state.companies[companyId];
        });
    }
    static authenticatedCompany(state) {
        return state.companies[state.authenticatedCompanyId];
    }
    getCompany(ctx, { id, silence404 }) {
        const { companies } = ctx.getState();
        if (!companies[id]) {
            return this.companyWebservice.get(id).pipe(switchMap(company => ctx.dispatch(new GetCompanySuccess(company))), silence404 // ADR #006
                ? catchError((err) => {
                    ErrorHandlerService.silence(err, err instanceof HttpErrorResponse && err.status === 404);
                    return throwError(err);
                })
                : tap(() => { }));
        }
    }
    getCompanySuccess(ctx, { company }) {
        return ctx.setState(patch({
            companies: patch({
                [company._id]: company,
            }),
        }));
    }
    setAuthenticatedCompany(ctx, { id }) {
        ctx.patchState({ authenticatedCompanyId: id });
        return ctx.dispatch(new GetCompany(id, false));
    }
};
__decorate([
    Action(GetCompany),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, GetCompany]),
    __metadata("design:returntype", void 0)
], CompaniesState.prototype, "getCompany", null);
__decorate([
    Action(GetCompanySuccess),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, GetCompanySuccess]),
    __metadata("design:returntype", void 0)
], CompaniesState.prototype, "getCompanySuccess", null);
__decorate([
    Action(SetAuthenticatedCompany),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SetAuthenticatedCompany]),
    __metadata("design:returntype", void 0)
], CompaniesState.prototype, "setAuthenticatedCompany", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [CompaniesStateModel]),
    __metadata("design:returntype", void 0)
], CompaniesState, "authenticatedCompany", null);
CompaniesState = CompaniesState_1 = __decorate([
    State({
        name: 'companies',
        defaults: defaultState,
    }),
    __metadata("design:paramtypes", [CompanyWebservice])
], CompaniesState);
export { CompaniesState };
