import * as __NgCli_locale_1 from "@angular/common/locales/fr";
import * as __NgCli_locale_2 from "@angular/common";
__NgCli_locale_2.registerLocaleData(__NgCli_locale_1.default);
import { enableProdMode } from '@angular/core';
import { environment } from '@env/environment';
import * as __NgCli_bootstrap_1 from "./app/core/core.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (!environment.dev) {
    enableProdMode();
}
document.addEventListener('DOMContentLoaded', () => {
    __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.CoreModuleNgFactory)
        .catch(err => console.log(err));
});
