import { environment } from '@env/environment';
const ɵ0 = {
    appId: environment.applicationId,
    baseUrl: environment.api.authentication,
}, ɵ1 = {
    appId: environment.applicationId,
    baseUrl: environment.api.contact,
}, ɵ2 = {
    baseUrl: environment.api.recruiter,
};
export class FeaturesModule {
}
export { ɵ0, ɵ1, ɵ2 };
