import { Routes } from '@angular/router';
import { InitGuard } from '@core/guards/init.guard';
import { MustHaveRecruiterGuard } from '@core/guards/must-have-recruiter.guard';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { NotFoundComponent } from '@features/not-found/not-found.component';
const ɵ0 = () => import("./home/home.module.ngfactory").then(m => m.HomeModuleNgFactory), ɵ1 = () => import("./feature/feature.module.ngfactory").then(m => m.FeatureModuleNgFactory), ɵ2 = () => import("./pricing/pricing.module.ngfactory").then(m => m.PricingModuleNgFactory), ɵ3 = () => import("./legal/legal.module.ngfactory").then(m => m.LegalModuleNgFactory), ɵ4 = { unhandled404: true };
const routes = [
    {
        path: FeaturesRoutingEnum.Home,
        pathMatch: 'full',
        canActivate: [InitGuard, MustHaveRecruiterGuard],
        loadChildren: ɵ0,
    },
    {
        path: FeaturesRoutingEnum.Feature,
        canActivate: [InitGuard, MustHaveRecruiterGuard],
        loadChildren: ɵ1,
    },
    {
        path: FeaturesRoutingEnum.Pricing,
        canActivate: [InitGuard, MustHaveRecruiterGuard],
        loadChildren: ɵ2,
    },
    {
        path: '',
        canActivate: [InitGuard, MustHaveRecruiterGuard],
        loadChildren: ɵ3,
    },
    {
        path: FeaturesRoutingEnum.NotFound,
        canActivate: [InitGuard, MustHaveRecruiterGuard],
        component: NotFoundComponent,
    },
    {
        path: '**',
        component: NotFoundComponent,
        data: ɵ4,
    },
];
export class FeaturesRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
