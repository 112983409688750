import * as atob from 'atob';
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "@nguniversal/express-engine/tokens";
import * as i2 from "ngx-cookie-service";
export class GlobalService {
    // tslint:disable:cognitive-complexity
    constructor(request, cookieService) {
        this.cookieService = cookieService;
        if (request) {
            this.init(request.jwt || null, request.refreshToken || null);
        }
        else {
            const rawTokens = JSON.parse(this.cookieService.get(GlobalService.TOKEN_KEY) || 'null');
            this.init(rawTokens && rawTokens['token'] ? rawTokens['token'] : null, rawTokens && rawTokens['refreshToken'] ? rawTokens['refreshToken'] : null);
        }
    }
    get tokens() {
        return this._tokens;
    }
    get userId() {
        return this._userId;
    }
    get roles() {
        return this._roles;
    }
    parseJwt(jwt) {
        return JSON.parse(atob(jwt.split('.')[1]));
    }
    init(token, refreshToken) {
        this._tokens =
            token && refreshToken
                ? {
                    token,
                    refreshToken,
                }
                : null;
        this._userId = this._tokens ? this.parseJwt(this._tokens.token)['user-id'] : null;
        this._roles = this._tokens ? this.parseJwt(this._tokens.token)['roles'] : null;
    }
}
GlobalService.TOKEN_KEY = 'ats-app_tokens';
GlobalService.EXPIRY_KEY = 'ats-app_expiry';
GlobalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalService_Factory() { return new GlobalService(i0.ɵɵinject(i1.REQUEST, 8), i0.ɵɵinject(i2.CookieService)); }, token: GlobalService, providedIn: "root" });
