var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var RecruitersState_1;
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { SetAuthenticatedCompany } from '@store/companies/companies.actions';
import { GetRecruiterByUserId, GetRecruiterSuccess, HandleErrors, SetAuthenticatedRecruiter, } from '@store/recruiters/recruiters.actions';
import { SessionState } from '@store/session/session.state';
import { RecruiterWebservice } from '@wizbii/webservices';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, first, switchMap } from 'rxjs/operators';
export class RecruitersStateModel {
}
const defaultState = {
    authenticatedRecruiterId: null,
    recruiters: {},
    error: null,
};
export const recruiterRefusedText = 'Recruiter refused';
let RecruitersState = RecruitersState_1 = class RecruitersState {
    constructor(recruiterWebservice, store) {
        this.recruiterWebservice = recruiterWebservice;
        this.store = store;
    }
    static authenticatedRecruiter(state) {
        return state.recruiters[state.authenticatedRecruiterId];
    }
    static authenticatedRecruiterId(state) {
        return state.authenticatedRecruiterId;
    }
    getRecruiterByUserId(ctx, { id }) {
        const { recruiters } = ctx.getState();
        if (!recruiters[id]) {
            return this.recruiterWebservice.getByUserId(id).pipe(switchMap(profile => ctx.dispatch(new GetRecruiterSuccess(profile))), catchError(error => ctx.dispatch(new HandleErrors(error))));
        }
    }
    getRecruiterSuccess(ctx, { recruiter }) {
        return ctx.setState(patch({
            authenticatedRecruiterId: recruiter._id,
            recruiters: patch({
                [recruiter._id]: recruiter,
            }),
        }));
    }
    setAuthenticatedRecruiter(ctx, { id }) {
        return ctx.dispatch(new GetRecruiterByUserId(id)).pipe(switchMap(() => this.store.select(RecruitersState_1.authenticatedRecruiter)), filter(recruiter => !!recruiter), first(), switchMap(recruiter => ctx.dispatch(new SetAuthenticatedCompany(recruiter.companyId))));
    }
    handleErrors(ctx, { error }) {
        // tslint:disable no-small-switch
        switch (error.status) {
            // The Job doesn't exist so => 404
            case 404: {
                if (error.error === recruiterRefusedText) {
                    console.error(recruiterRefusedText);
                    ctx.patchState({ error });
                    return of();
                }
                console.error('Not found');
                ctx.patchState({ error });
                const hasTokens = !!this.store.selectSnapshot(SessionState.tokens);
                return hasTokens
                    ? EMPTY
                    : ctx.dispatch(new Navigate(['/', FeaturesRoutingEnum.NotFound], undefined, { skipLocationChange: true }));
            }
            default: {
                console.error(`Code ${error.status} => ${error.statusText}`);
                return ctx.patchState({ error });
            }
        }
        // tslint:enable no-small-switch
    }
};
__decorate([
    Action(GetRecruiterByUserId),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, GetRecruiterByUserId]),
    __metadata("design:returntype", void 0)
], RecruitersState.prototype, "getRecruiterByUserId", null);
__decorate([
    Action(GetRecruiterSuccess),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, GetRecruiterSuccess]),
    __metadata("design:returntype", void 0)
], RecruitersState.prototype, "getRecruiterSuccess", null);
__decorate([
    Action(SetAuthenticatedRecruiter),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SetAuthenticatedRecruiter]),
    __metadata("design:returntype", void 0)
], RecruitersState.prototype, "setAuthenticatedRecruiter", null);
__decorate([
    Action(HandleErrors),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, HandleErrors]),
    __metadata("design:returntype", void 0)
], RecruitersState.prototype, "handleErrors", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [RecruitersStateModel]),
    __metadata("design:returntype", void 0)
], RecruitersState, "authenticatedRecruiter", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [RecruitersStateModel]),
    __metadata("design:returntype", void 0)
], RecruitersState, "authenticatedRecruiterId", null);
RecruitersState = RecruitersState_1 = __decorate([
    State({
        name: 'recruiters',
        defaults: defaultState,
    }),
    __metadata("design:paramtypes", [RecruiterWebservice, Store])
], RecruitersState);
export { RecruitersState };
