var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { isPlatformBrowser } from '@angular/common';
import { GlobalService } from '@core/services/global.service';
import { Action, Selector, State } from '@ngxs/store';
import { SetAuthenticatedRecruiter } from '@store/recruiters/recruiters.actions';
import { Init, InitUser, Logout, RefreshTokensStore, SetTokens } from '@store/session/session.actions';
import { DataStorageService, deserializeJwt } from '@wizbii/angular-utilities';
import { AuthenticationWebservice } from '@wizbii/webservices';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
export var SessionStateEnum;
(function (SessionStateEnum) {
    SessionStateEnum["Init"] = "INIT";
    SessionStateEnum["Logged"] = "LOGGED";
    SessionStateEnum["Recovered"] = "RECOVERED";
    SessionStateEnum["NotLogged"] = "NOT_LOGGED";
})(SessionStateEnum || (SessionStateEnum = {}));
export class SessionStateModel {
}
const defaultState = {
    tokens: null,
    loading: false,
    state: SessionStateEnum.Init,
    error: null,
    errorMessage: null,
    user: null,
};
let SessionState = class SessionState {
    constructor(dataStorageService, authService, cookieService, globalService, platformId, document) {
        this.dataStorageService = dataStorageService;
        this.authService = authService;
        this.cookieService = cookieService;
        this.globalService = globalService;
        this.platformId = platformId;
        this.document = document;
    }
    // tslint:disable:no-identical-functions
    static tokens(state) {
        return state.tokens;
    }
    static isInitialized(state) {
        return state.state !== SessionStateEnum.Init;
    }
    static isLogged(state) {
        return state.state === SessionStateEnum.Logged;
    }
    init(ctx, { tokens }) {
        const realTokens = tokens ? tokens : this.readTokens();
        if (!!realTokens) {
            this.globalService.init(realTokens.token, realTokens.refreshToken);
            ctx.patchState({ tokens: realTokens });
            const userId = deserializeJwt(realTokens.token)['user-id'];
            return ctx.dispatch([new InitUser(userId), new SetAuthenticatedRecruiter(userId)]).pipe(switchMap(() => of(ctx.patchState({ state: SessionStateEnum.Logged }))), catchError(() => of(ctx.patchState({ state: SessionStateEnum.NotLogged }))));
        }
        return ctx.patchState({ state: SessionStateEnum.NotLogged });
    }
    initUser(ctx, { id }) {
        return this.authService.getUserOverview(id).pipe(switchMap(user => of(ctx.patchState({ user }))));
    }
    refreshTokensStore(ctx, action) {
        const { tokens } = action;
        return ctx.patchState({
            tokens,
        });
    }
    logout(ctx) {
        this.forgetTokens();
        if (isPlatformBrowser(this.platformId)) {
            this.document.location = this.document.location.origin;
        }
        return ctx.setState(defaultState);
    }
    setTokens(ctx, action) {
        const { tokens } = action;
        this.writeTokens(tokens);
        return ctx.patchState({
            tokens,
        });
    }
    readTokens() {
        const rawTokens = JSON.parse(this.cookieService.get(GlobalService.TOKEN_KEY) || 'null');
        return !!rawTokens ? rawTokens : null;
    }
    writeTokens(tokens) {
        const cookieDomain = this.getCookieDomain();
        const expiryExists = this.cookieService.check(GlobalService.EXPIRY_KEY);
        const msIn390Days = 1000 * 3600 * 24 * 390;
        const expiry = expiryExists
            ? new Date(this.cookieService.get(GlobalService.EXPIRY_KEY))
            : new Date(Date.now() + msIn390Days);
        if (!expiryExists) {
            this.cookieService.set(GlobalService.EXPIRY_KEY, expiry.getTime().toString(), expiry, '/', cookieDomain, cookieDomain !== 'localhost', cookieDomain === 'localhost' ? 'Lax' : 'None');
        }
        this.cookieService.set(GlobalService.TOKEN_KEY, JSON.stringify(tokens), expiry, '/', cookieDomain, cookieDomain !== 'localhost', cookieDomain === 'localhost' ? 'Lax' : 'None');
    }
    forgetTokens() {
        const cookieDomain = this.getCookieDomain();
        this.dataStorageService.remove(GlobalService.TOKEN_KEY);
        this.cookieService.set(GlobalService.TOKEN_KEY, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '/', cookieDomain, cookieDomain !== 'localhost', cookieDomain === 'localhost' ? 'Lax' : 'None');
        this.cookieService.set(GlobalService.EXPIRY_KEY, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), '/', cookieDomain, cookieDomain !== 'localhost', cookieDomain === 'localhost' ? 'Lax' : 'None');
    }
    getCookieDomain() {
        const cookieSubDomain = ['', ...this.document.location.hostname.split('.').slice(-2)].join('.');
        return cookieSubDomain === '.localhost' ? 'localhost' : cookieSubDomain;
    }
};
__decorate([
    Action(Init),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Init]),
    __metadata("design:returntype", void 0)
], SessionState.prototype, "init", null);
__decorate([
    Action(InitUser),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, InitUser]),
    __metadata("design:returntype", void 0)
], SessionState.prototype, "initUser", null);
__decorate([
    Action(RefreshTokensStore),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, RefreshTokensStore]),
    __metadata("design:returntype", void 0)
], SessionState.prototype, "refreshTokensStore", null);
__decorate([
    Action(Logout),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SessionState.prototype, "logout", null);
__decorate([
    Action(SetTokens),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SetTokens]),
    __metadata("design:returntype", void 0)
], SessionState.prototype, "setTokens", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [SessionStateModel]),
    __metadata("design:returntype", void 0)
], SessionState, "tokens", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [SessionStateModel]),
    __metadata("design:returntype", void 0)
], SessionState, "isInitialized", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [SessionStateModel]),
    __metadata("design:returntype", void 0)
], SessionState, "isLogged", null);
SessionState = __decorate([
    State({
        name: 'session',
        defaults: defaultState,
    }),
    __metadata("design:paramtypes", [DataStorageService,
        AuthenticationWebservice,
        CookieService,
        GlobalService,
        Object, Object])
], SessionState);
export { SessionState };
