var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var ConsentsState_1;
import { Action, createSelector, State } from '@ngxs/store';
import { SetConsent, SetConsents } from '@store/consents/consents.actions';
import { fromEvent } from 'rxjs';
let ConsentsState = ConsentsState_1 = class ConsentsState {
    constructor(document) {
        this.document = document;
    }
    static consent(key) {
        return createSelector([ConsentsState_1], (state) => state[key]);
    }
    ngxsAfterBootstrap(ctx) {
        fromEvent(this.document, 'WizbiiGdpr.consentsChange').subscribe(({ WizbiiGdpr: { consent } }) => {
            ctx.dispatch(new SetConsents(consent));
        });
        // Listen for a consent changed on its own
        fromEvent(this.document, 'WizbiiGdpr.consentChange').subscribe(({ WizbiiGdpr: { consent } }) => {
            ctx.dispatch(new SetConsent(consent));
        });
    }
    setConsents(ctx, { consents }) {
        ctx.setState([].concat(...Object.values(consents)).reduce((acc, current) => {
            acc[current.key] = current.value;
            return acc;
        }, {}));
    }
    setConsent(ctx, { consent }) {
        ctx.patchState({ [consent.key]: consent.value });
    }
};
__decorate([
    Action(SetConsents),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SetConsents]),
    __metadata("design:returntype", void 0)
], ConsentsState.prototype, "setConsents", null);
__decorate([
    Action(SetConsent),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SetConsent]),
    __metadata("design:returntype", void 0)
], ConsentsState.prototype, "setConsent", null);
ConsentsState = ConsentsState_1 = __decorate([
    State({
        name: 'consents',
        defaults: {},
    }),
    __metadata("design:paramtypes", [Object])
], ConsentsState);
export { ConsentsState };
