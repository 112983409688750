import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
/**
 * Provide access to the router's previous URL, for instance, to distinguish initial from subsequent navigations.
 * https://stackoverflow.com/a/48866813/758806
 */
export class RouterExtensionService {
    constructor(router) {
        this.router = router;
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
        });
    }
}
RouterExtensionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouterExtensionService_Factory() { return new RouterExtensionService(i0.ɵɵinject(i1.Router)); }, token: RouterExtensionService, providedIn: "root" });
